import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';
import 'react-phone-number-input/style.css';
import './CreateJob.scss';
import SimpleReactValidator from 'simple-react-validator';
import { CreateDashboard } from '../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
  },
};
const adress_options = [
  { value: 'H', label: 'Home Address' },
  { value: 'O', label: 'Office Address' },
  { value: 'X', label: 'Other Address' }
]
const phone_options = [
  { value: 'H', label: 'Home Phone' },
  { value: 'O', label: 'Office Phone' },
  { value: 'M', label: 'Mobile Phone' }
]
const gender_options = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
];
const email_options = [
  { value: 'O', label: 'Office Email' },
  { value: 'P', label: 'Personal Email' },
]
const state_options = [
  {
    "value": "AN",
    "label": "Andaman and Nicobar Islands"
  },
  {
    "value": "AP",
    "label": "Andhra Pradesh"
  },
  {
    "value": "AR",
    "label": "Arunachal Pradesh"
  },
  {
    "value": "AS",
    "label": "Assam"
  },
  {
    "value": "BR",
    "label": "Bihar"
  },
  {
    "value": "CG",
    "label": "Chandigarh"
  },
  {
    "value": "CH",
    "label": "Chhattisgarh"
  },
  {
    "value": "DH",
    "label": "Dadra and Nagar Haveli"
  },
  {
    "value": "DD",
    "label": "Daman and Diu"
  },
  {
    "value": "DL",
    "label": "Delhi"
  },
  {
    "value": "GA",
    "label": "Goa"
  },
  {
    "value": "GJ",
    "label": "Gujarat"
  },
  {
    "value": "HR",
    "label": "Haryana"
  },
  {
    "value": "HP",
    "label": "Himachal Pradesh"
  },
  {
    "value": "JK",
    "label": "Jammu and Kashmir"
  },
  {
    "value": "JH",
    "label": "Jharkhand"
  },
  {
    "value": "KA",
    "label": "Karnataka"
  },
  {
    "value": "KL",
    "label": "Kerala"
  },
  {
    "value": "LD",
    "label": "Lakshadweep"
  },
  {
    "value": "MP",
    "label": "Madhya Pradesh"
  },
  {
    "value": "MH",
    "label": "Maharashtra"
  },
  {
    "value": "MN",
    "label": "Manipur"
  },
  {
    "value": "ML",
    "label": "Meghalaya"
  },
  {
    "value": "MZ",
    "label": "Mizoram"
  },
  {
    "value": "NL",
    "label": "Nagaland"
  },
  {
    "value": "OR",
    "label": "Odisha"
  },
  {
    "value": "PY",
    "label": "Puducherry"
  },
  {
    "value": "PB",
    "label": "Punjab"
  },
  {
    "value": "RJ",
    "label": "Rajasthan"
  },
  {
    "value": "SK",
    "label": "Sikkim"
  },
  {
    "value": "TN",
    "label": "Tamil Nadu"
  },
  {
    "value": "TS",
    "label": "Telangana"
  },
  {
    "value": "TR",
    "label": "Tripura"
  },
  {
    "value": "UK",
    "label": "Uttarakhand"
  },
  {
    "value": "UP",
    "label": "Uttar Pradesh"
  },
  {
    "value": "WB",
    "label": "West Bengal"
  }
];
const CreateJob = (props) => {

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [addresstype, setAddresstype] = useState("");
  const [addressline1, setAddressline1] = useState("");
  const [addressline2, setAddressline2] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");
  const [dob, setDob] = useState("");
  const [phonetype, setPhoneType] = useState("");
  const [email, setEmail] = useState("");
  const [emailtype, setEmailtype] = useState("");
  const [phone, setPhone] = useState("");
  const [documentnumber, setDocumentnumber] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();

  const handleSubmit = (event) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
    if (formValid) {
      let data = {
        "first_name": firstname,
        "last_name": lastname,
        "address_line_1": addressline1,
        "address_line_2": addressline2,
        "locality": locality,
        "city": city,
        "state": state.value,
        "postal": postal,
        "phone": phone,
        "date_of_birth": dob,
        "pan_card": documentnumber,
        "gender": gender.value
      }
      setDisableSubmit(true);
      CreateDashboard(data).then(response => {
        props.closeModal();
        setDisableSubmit(true);
      });

    }

    event.preventDefault();
  }

  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        ariaHideApp={false}
        contentLabel="Create Job"
        style={customStyles}

      >
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg="2"></Col>
            <Col lg="4">
              <Form.Group className="mb-3">
                <Form.Control name="firstname" value={firstname} type="text"
                  placeholder="First Name" onChange={(e) => setFirstname(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('firstname', firstname, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control name="addressline1" value={addressline1} type="text"
                  placeholder="Address Line 1" onChange={(e) => setAddressline1(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('addressline1', addressline1, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Control name="locality" value={locality} type="text"
                  placeholder="Locality" onChange={(e) => setLocality(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('locality', locality, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Select name="state" value={state} options={state_options}
                  onChange={(e) => setState(e)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('state', state, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Select name="addresstype" value={addresstype}
                  options={adress_options} onChange={(e) => setAddresstype(e)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('addresstype', addresstype, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Select name="phonetype" value={phonetype}
                  options={phone_options} onChange={(e) => setPhoneType(e)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('phonetype', phonetype, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control name="email" value={email}
                  type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('email', email, 'required|email')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Select name="gender" value={gender} options={gender_options}
                  onChange={(e) => { setGender(e); }} />
                <div className='mt-2'>
                  {simpleValidator.current.message('gender', gender, 'required')}
                </div>
              </Form.Group>

            </Col>
            <Col lg="4">
              <Form.Group className="mb-3">
                <Form.Control name="lastname" value={lastname} type="text"
                  placeholder="Last Name" onChange={(e) => setLastname(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('lastname', lastname, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control name="addressline2" value={addressline2} type="text"
                  placeholder="Address Line 2" onChange={(e) => setAddressline2(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('addressline2', addressline2, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" name="city" value={city}
                  placeholder="City" onChange={(e) => setCity(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('city', city, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Control type="text" name="postal" value={postal}
                  placeholder="Postal" onChange={(e) => setPostal(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('postal', postal, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control name="dob" value={dob} type="date"
                  placeholder="DOB" onChange={(e) => setDob(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('dob', dob, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" name="phone" value={phone}
                  placeholder="Phone" onChange={(e) => setPhone(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('phone', phone, "required|numeric|between:10,10,string")}
                </div>
              </Form.Group>
              <Form.Group className="mb-3" >
                <Select options={email_options} name="emailtype" value={emailtype}
                  onChange={(e) => setEmailtype(e)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('emailtype', emailtype, 'required')}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control name="documentnumber" value={documentnumber} type="text"
                  placeholder="Document Number" onChange={(e) => setDocumentnumber(e.target.value)} />
                <div className='mt-2'>
                  {simpleValidator.current.message('documentnumber', documentnumber, 'required')}
                </div>
              </Form.Group>
            </Col>
            <Col lg="2"></Col>
          </Row>
          <Row>
            <Col lg="2"></Col>
            <Col lg="8" className='text-center'>
              <Button type="submit" variant="primary" disable={disableSubmit}>Submit</Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button onClick={props.closeModal} variant="secondary">Close</Button>
            </Col>
            <Col lg="2"></Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default CreateJob;